import Home from './Home';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        exact: true,
    }
];
  
export default routes as any;