/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import Theme, { applyTheme, DefaultTheme } from 'orbiter-web-components/src/components/Theme';
import SimpleGrid from 'orbiter-web-components/src/components/grid/SimpleGrid';
import VerticalAlign from 'orbiter-web-components/src/components/align/VerticalAlign';
import Button from 'orbiter-web-components/src/components/button/Button';
import { ThemeProvider } from 'emotion-theming';
import { motion } from 'framer-motion';
import Spacer from 'orbiter-web-components/src/components/spacer/Spacer';

export const noMargin = (tt: Theme) => {
    return css`
        padding: 0;
        background-color: ${applyTheme(tt, (t: Theme) => t.colors.secondaryBackground)};
        margin: 0;
    `;
};

const theme: Theme = {
    ...DefaultTheme,
    font: {
        ...DefaultTheme.font,
        fontFamily: {
            ...DefaultTheme.font.fontFamily,
            primary: '\'Prompt\', sans-serif',
        }
    },
    colors: {
        ...DefaultTheme.colors,
        primaryText: '#151616',
    },
    button: {
        ...DefaultTheme.button,
    },
};

function rand(start: number, end: number): number {
    return Math.floor(Math.random() * end) + start;
}

class Triangle extends React.Component<{ scale: number, x: number, y: number, rotation: number, fill?: string }, any> {
    public render() {
        return (
            <div css={(t: Theme) => css`
                position: absolute;
                top: ${this.props.y}%;
                left: ${this.props.x}%;
                z-index: 1000;
            `} >
                <motion.div
                    animate={{
                        y: [0, -rand(0, 10), 0, rand(0, 10), 0],
                        x: [0, rand(0, 10), 0 ,-rand(0, 10), 0],
                        scale: [1, 1.1, 1, 0.9, 1]
                    }}
                    transition={{
                        duration: 15,
                        loop: Infinity,
                        repeatDelay: 0,
                        delay: rand(0, 10),
                    }}
                >
                    <svg css={css`transform: rotate(${this.props.rotation}deg);`} width={this.props.scale} height={this.props.scale} viewBox="0 0 209 213" preserveAspectRatio="" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d)">
                                <path d="M149.848 72.3885L98.2985 192.293L20.233 87.6972L149.848 72.3885Z" fill={this.props.fill !== undefined ? this.props.fill : '#FCC800'} />
                            </g>
                            <defs>
                                <filter id="filter0_d" x="0.233398" y="0.0905228" width="208.648" height="212.203" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="10" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                </filter>
                            </defs>
                    </svg>
                </motion.div>
            </div>
        );
    }
}

const regularSectionStyle = css`
    text-align: center;
    font-size: 20px;
    padding: 20px;

    h1 {
        margin-bottom: 40px;
    }

    p {
        margin: 0;
    }
`;

class Home extends React.Component<any, any> {

    public render() {

        return (
            <ThemeProvider theme={theme}>
                <div css={css`position: relative; width: 100vw; height: 100vh; overflow-x: hidden;`}>
                    <Triangle scale={200} x={0} y={0} rotation={30} fill="#2F2F2F" />
                    <Triangle scale={100} x={48} y={10} rotation={50} />
                    <Triangle scale={180} x={6} y={70} rotation={10} fill="#2F2F2F" />

                    <SimpleGrid colSize="md" cols={2} fluid={true} containerStyle={() => {
                        return css`
                            background: radial-gradient(540.00px at 50% 50%, #FCC800 0%, #DDAF00 100%);
                        `;
                    }}>
                        <VerticalAlign verticalAlignStyle={() => css`background: linear-gradient(137.9deg, #F3F3F3 4.13%, #FFFFFF 100%); box-shadow: 0px -30px 29.1892px rgba(0, 0, 0, 0.25); overflow: hidden;`}>
                            <div css={css`text-align: center; padding: 20px;`}>
                                <img src={'/images/logo.svg'} css={css`max-width: 80%; padding: 20px;`} />
                                <h1 css={(t: Theme) => css`
                                    font-family: ${t.font.fontFamily.primary};
                                    font-weight: bold;
                                    font-size: 60px;
                                    color: ${theme.colors.primaryText};
                                    margin: 60px 0;

                                    @media only screen and (max-width: 600px) {
                                        font-size: 40px;
                                    }
                                `}>Bedrijfssoftware op maat.</h1>
                                <div>
                                    <a href="tel:+32470050241"><Button>Bel ons</Button></a>
                                    <a href="mailto:daan@onlineorbit.com"><Button buttonStyle={() => css`margin-left: 10px;`} >Mail ons</Button></a>
                                </div>
                            </div>
                        </VerticalAlign>
                        <VerticalAlign verticalAlignStyle={() => css`text-align: center; overflow: hidden;`}>
                            <motion.div
                                animate={{
                                    y: [0, 30, 0, 30, 0],
                                }}
                                transition={{
                                    duration: 20,
                                    loop: Infinity,
                                    repeatDelay: 0
                                }}
                            >
                                <img src={'/images/phone.svg'} css={css`max-width: 100%; padding: 20px;`} />
                            </motion.div>
                        </VerticalAlign>
                    </SimpleGrid>
                    <VerticalAlign verticalAlignStyle={(t: Theme) => css`
                            background-color: #EDEDED;
                            ${regularSectionStyle}
                        `}>
                            <div css={css`max-width: 800px; padding: 10px; margin-left: auto; margin-right: auto;`}>
                                <h1>Onze missie.</h1>
                                <p>Technologie moet eenvoudig maar krachtig, mooi maar functioneel zijn. Het moet inspireren, het moet deuren openen.</p>
                                <p>Technologie moet het leven eenvoudiger maken, niet complexer.</p>
                                <p>Dat is precies wat we voor je doen.</p>
                                
                                <Spacer height="40px" />
                                <a href="mailto:daan@onlineorbit.com"><Button buttonStyle={() => css`margin-left: 10px;`} >Vind ik ook!</Button></a>
                            </div>
                    </VerticalAlign>
                    <VerticalAlign verticalAlignStyle={(t: Theme) => css`
                            background-color: white;
                            ${regularSectionStyle}
                        `}>
                            <div css={css`max-width: 800px; padding: 10px; margin-left: auto; margin-right: auto;`}>
                                <h1>Onze technologie.</h1>
                                <p>We ontwikkelen moderne software op maat van uw bedrijf. Hierbij vertrekken we steeds bij de persoon die de software zal gebruiken. We baseren alles wat we doen op drie kernbegrippen.</p>
                                <br/>
                                <p>Onze applicaties en platformen zijn <b>eenvoudig in gebruik</b>, zodat iedereen zoveel mogelijk aandacht kan besteden aan wat echt waardevol is voor uw bedrijf.</p>
                                <br/>
                                <p>Alles wordt op maat gemaakt, zo zijn onze systemen ongelofelijk <b>flexibel</b>.</p>
                                <br/>
                                <p>Ten slotte worden al onze applicaties ten volle <b>geïntegreerd</b> met bestaande toepassingen. Dit is van groot belang om de productiviteit van uw medewerkers te verzekeren.</p>

                                <Spacer height="40px" />
                                <a href="mailto:daan@onlineorbit.com"><Button buttonStyle={() => css`margin-left: 10px;`} >Dat zoek ik!</Button></a>
                            </div>
                    </VerticalAlign>
                    <VerticalAlign minHeight="100px" verticalAlignStyle={(t: Theme) => css`
                            background-color: #EDEDED;
                            ${regularSectionStyle}
                        `}>
                            <div>
                                <h1>We staan voor u klaar.</h1>
                                <div>
                                    <a href="tel:+32470050241"><Button>Bel ons</Button></a>
                                    <a href="mailto:daan@onlineorbit.com"><Button buttonStyle={() => css`margin-left: 10px;`} >Mail ons</Button></a>
                                </div>
                                <Spacer height="40px" />
                            </div>
                    </VerticalAlign>
                </div>
            </ThemeProvider>
        );
    }
}

export default Home;