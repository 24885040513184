import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import 'orbiter-web-components/src/bootstrap.css';

const App = (a: {routes: any, serverLoadedData: any}) => {
  return (<Switch>
    {a.routes.map((route: any, index: number) => {
      return (<Route key={index} path={route.path} exact={route.exact} render={(props) => React.createElement(route.component, {
        ...props,
        sd: {
          parentLoader: {
            key: 'root' + index,
            keyPath: 'root' + index,
          },
          serverLoadedData: a.serverLoadedData,
        },
        sdKey: 'root',
      })} />);
    })}
  </Switch>);
};

export default App;
